import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
  return ({
    main: {
      '& a': {
        textDecoration: 'none',
        color: theme.palette.primary.menu,
        '&:hover': {
          textDecoration: 'none',
          fontSize:'1.6rem',
          color: theme.palette.primary.main,

        },
        '&:active': {
          textDecoration: 'none',
        }
      },
      '& h1': {
      color: theme.palette.primary.main,
      fontSize: '2.2rem',
      },
      '& h2': {
        color: theme.palette.primary.main,
        fontSize: '2rem',
        textTransform: 'uppercase',
      },
      '& h3': {
        color: theme.palette.primary.main,
        fontSize: '1.5rem',
        textTransform: 'uppercase',
      },
      '& h4': {
        color: theme.palette.primary.main,
        fontSize: '1.2rem',
        textTransform: 'uppercase',
      },
      '& li': {
        fontSize: '1.5rem',
      },
      '& p': {
        fontSize: '1.5rem',
        lineHeight: '2rem',
        '& strong': {
          color: theme.palette.primary.main,
          fontWeight: '700',
        },
        '& em': {
          color: theme.palette.primary.main,
          fontWeight: '700',
        }
      },
    },
    link: {
      padding: '1rem'
    },
    contacts: {
      textAlign: 'center'
    }
  })
})

export default useStyles