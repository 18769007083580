import React from "react"
import Layout from "../components/Layout"
import { useStaticQuery, graphql } from "gatsby"
import useStyles from '../styles/contactStyles'
import PageTitle from '../components/PageTitle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXingSquare, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { Link } from '@material-ui/core'
import SEO from '../components/seo'

const Contact = () => {
  const classes = useStyles()
  const query = useStaticQuery(graphql`
  {
      allMarkdownRemark(filter: {frontmatter: {id: {eq: "contact"}}}) {
        nodes {
          frontmatter {
            title
            seoDescription
            seoKeywords
            seoTitle
            contacts {
              email
              linkedin
              xing
            }
          }
          html
        }
      }
    }
  `)

  const pageTitle = query.allMarkdownRemark.nodes[0].frontmatter.title
  const pageContent = query.allMarkdownRemark.nodes[0].frontmatter.contacts
  const seoDescription = query.allMarkdownRemark.nodes[0].frontmatter.seoDescription
  const seoKeywords = query.allMarkdownRemark.nodes[0].frontmatter.seoKeywords
  const seoTitle = query.allMarkdownRemark.nodes[0].frontmatter.seoTitle

  return (
    <Layout>
      <SEO title={seoTitle.length !== 0 ? seoTitle : pageTitle} description={seoDescription.length !== 0 ? seoDescription : null} keywords={seoKeywords.length !== 0 ? seoKeywords : null}/>
      <PageTitle title={pageTitle} />
      <div className={classes.main} dangerouslySetInnerHTML={{ __html: query.allMarkdownRemark.nodes[0].html }} />
      <div className={classes.contacts}>
        <Link href={pageContent.xing} target='_blank' className={classes.link}>
          <FontAwesomeIcon icon={faXingSquare} size="3x" />
        </Link>
        <Link href={pageContent.linkedin} target='_blank' className={classes.link}>
          <FontAwesomeIcon icon={faLinkedin} size="3x" />
        </Link>
        <Link href={pageContent.email} className={classes.link}>
          <FontAwesomeIcon icon={faPaperPlane} size="3x" />
        </Link>
      </div>
    </Layout>
  )
}

export default Contact